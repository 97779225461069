import React from 'react'

import PictoFast from '../../../assets/fast.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoFast,
  title: 'Concernant le taux immobilier, celui-ci se trouve donc au plus haut lorsque l’emprunt se rembourse sur 25 ans.'
}]

const RealEstateLoan = () => {
  return (
    <HomeContainer max={1200}>
      <HomeSectionTitle tag='h3'>
        La durée de l’emprunt : un facteur déterminant du taux immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le premier facteur entrant en ligne de mire dans le calcul du taux immobilier est la durée de l’emprunt.        
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En effet, plus l’emprunt s’étale dans le temps, plus la banque propose un taux élevé. Les emprunteurs peuvent ainsi choisir une période allant de 5 à 25 ans pour rembourser leur crédit immobilier.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        En effet, plus l'emprunt s'étale dans le temps plus la banque propose un taux élevé. Les emprunteurs peuvent ainsi choisir une période allant de 5 à 25 ans pour rembourser leur crédit immobilier.
      </HomeSectionDescription>
      <HomeSectionDescription>
        C’est intéressant pour augmenter son budget afin d’acheter un bien immobilier plus cher, mais, comme précisé précédemment, le taux immobilier sera bien plus important.
      </HomeSectionDescription>
      <HomeSectionDescription>
        <strong>Quoi qu’il en soit, la durée de l’emprunt est probablement le critère qui détermine le plus le taux immobilier.</strong>
      </HomeSectionDescription>
      </HomeContainer>
  )
}

export default RealEstateLoan
