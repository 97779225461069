import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const FormulaContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: 16px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => get(theme, 'lightOrange')};
  margin: 32px auto 0;
  text-align: center;

  ${media.greaterThan('sm')`
    margin: 40px auto 0;
  `}
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  font-weight: 600;
`

const RealEstateLoanIncome = () => {
  return (
    <HomeContainer max={1200}>
      <HomeSectionTitle>
        Autre élément important dans votre profil emprunteur&nbsp;: vos revenus
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        De toute évidence, un emprunteur avec de faibles revenus posera davantage de difficultés à la banque, qui pourra alors proposer un taux moins intéressant, voire refuser le prêt immobilier.    
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Dans ce cas, certains dispositifs permettent aux primo accédants d’obtenir des crédits immobiliers à taux zéro.
      </HomeSectionDescription>
      <FormulaContainer>
        <StyledHomeSectionDescription>
          → Les revenus sont calculés par la banque grâce au taux d’endettement, qui ne doit pas dépasser 35% du revenu net annuel.
        </StyledHomeSectionDescription>
      </FormulaContainer>
    </HomeContainer>
  )
}

export default RealEstateLoanIncome
