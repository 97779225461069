import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import PictoTaxIncome from '../../../assets/tax-income.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoTaxIncome,
  title: 'Un compte en banque qui n’est pas associé à un emprunt a donc plus de chances d’obtenir un crédit pour un bien immobilier.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`
const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
`

const TitleOrange = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'lightOrange')};
  padding: 0 10px 0 0;
  min-width: 80px;

  & > em {
    font-style: italic;
  }
`

const RealEstateRateRecap2 = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h3'>
      <TitleRow>
        <TitleOrange>→ 2.</TitleOrange> 
        Liquider les crédits conso en cours
      </TitleRow>
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les crédits de consommation sont monnaie courante, tout le monde emprunte au moins une fois dans sa vie, peu importe le motif. 
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Dans le cadre d’un achat immobilier, mieux vaut liquider tous ses crédits conso avant de se lancer dans la course au prêt immobilier. Le taux immobilier sera effectivement d’autant plus intéressant si la banque constate une situation financière stable, sans endettement quel qu’il soit. Certains établissements bancaires plus stricts peuvent même refuser d’accorder un prêt immobilier sous prétexte que d’autres crédits n’ont pas été remboursés totalement.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
    </Container>
  )
}

export default RealEstateRateRecap2
