import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import PictoBank from '../../../assets/bank.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoBank,
  title: 'Il convient tout de même de s’adresser à plusieurs banques afin d’obtenir différents taux immobiliers, les comparer et ainsi pouvoir choisir convenablement.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
`

const TitleOrange = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'lightOrange')};
  padding: 0 10px 0 0;
  min-width: 80px;

  & > em {
    font-style: italic;
  }
`

const RealEstateRateRecap1 = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h3'>
      <TitleRow>
        <TitleOrange>→ 1.</TitleOrange> 
        Fournir un apport personnel de 10%
      </TitleRow>
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Pour pouvoir obtenir un crédit immobilier, il est vivement recommandé de pouvoir justifier d’un apport personnel d’au moins 10% du prix d’achat du bien immobilier.      
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Bien que cela ne soit pas obligatoire, les banques sont de plus en plus réticentes à accorder un crédit si les emprunteurs ne peuvent pas fournir d’apport. À vrai dire, l’apport personnel est une condition sine qua non pour l’obtention d’un prêt immobilier. De toute évidence, plus l’apport personnel est important, plus le taux immobilier sera intéressant.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
    </Container>
  )
}

export default RealEstateRateRecap1
