import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import PictoPiggy from '../../../assets/piggy.inline.svg'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPiggy,
  title: 'Pour un crédit immobilier, il est recommandé de pouvoir justifier d’un apport de 10% du prix du bien, minimum.'
}]

const size = {
  lg: {
    height: '654'
  },
  xl: {
    height: '576'
  }
}

const RealEstateRateProfilLoan = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "le-profil-emprunteur-taux-immobilier.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={true}
    imageData={placeholderImage}
    backgroundColor='paleGrey'>
    <HomeSectionTitle tag='h3'>
      Le profil emprunteur à soigner pour un meilleur taux immobilier
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le deuxième facteur déterminant pour le taux immobilier est évidemment le profil emprunteur.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En réalité, plusieurs critères peuvent être cités pour établir le profil emprunteur. On retrouve notamment <strong>l’apport personnel</strong>
    </HomeSectionDescription>
    <HomeSectionDescription>
      C’est la somme d’argent minimale dont doit disposer l’emprunteur pour pouvoir réaliser son achat immobilier.
    </HomeSectionDescription>
    <PictoColContainer details={DETAILS_COL}/>
    <HomeSectionDescription>
      À noter que l’apport personnel n’est pas obligatoire pour demander un crédit, mais très peu d’établissements bancaires accordent d’emprunt sans.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default RealEstateRateProfilLoan
