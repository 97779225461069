import React from 'react'

import PictoBank from '../../../assets/bank.inline.svg'
import PictoFast from '../../../assets/fast.inline.svg'
import PictoFamily from '../../../assets/family.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoFast,
  title: 'Durée de l\'emprunt',
  description: '(plus la durée du prêt sera longue, plus le taux sera élevé)'
}, {
  icon: PictoFamily,
  title: 'Le profil de l\'emprunteur'
}, {
  icon: PictoBank,
  title: 'Le taux de l\'OAT',
  description: '(Obligation Assimilable du Trésor)'
}]

const TAEGBorrower = () => (
  <HomeContainer max={1200}>
    <HomeSectionTitle>
      Le taux dépend du profil de l'emprunteur
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Concrètement, plus le profil de l’emprunteur est soigné (pas de crédits en cours, bonne situation financière, bonne tenue des comptes personnels, pas de découvert, apport suffisant, etc …), plus le taux devient intéressant.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En d’autres termes, le taux immobilier reflète donc plus ou moins la bonne volonté de la banque à vouloir prêter de l’argent.
    </HomeSectionDescription>
    <HomeSectionDescription>
      De toute évidence, un établissement bancaire est plus réticent à accorder un crédit immobilier à un acheteur avec une situation financière compliquée et avec un apport personnel moindre. Certains profils peuvent donc bénéficier d’un taux immobilier relativement haut.
    </HomeSectionDescription>
    <br/>
    <HomeSectionDescription>
      Ainsi, le calcul du taux immobilier se fait selon plusieurs critères :
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
  </HomeContainer>
)

export default TAEGBorrower