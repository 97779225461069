import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '400'
  },
  xxl: {
    height: '500'
  }
}

const RealEstateRateComposition = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "la-composition-du-taux-immobilier.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={false}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        La composition du taux immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Tous les biens ne sont pas éligibles au dispositif loi Pinel.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Folhomee vous présente les conditions d'éligibilité et les zones géographiques concernées par ce type d'investissemeent immobilier locatif. Pour tous savoir sur le dispositif Pinel, des biens éligibles aux zones concernées, c'est ici.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default RealEstateRateComposition
