import React from 'react'

import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RealEstateBank = () => {
  return (
    <HomeContainer max={1200}>
      <HomeSectionTitle>
        La durée de l’emprunt : un facteur déterminant du taux immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les frais appliqués sur le taux immobilier dépendent encore une fois de l’établissement bancaire sélectionné.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ce dernier peut appliquer les frais qu’il souhaite, tant que le taux immobilier ne dépasse pas le seuil fixé par la Banque de France. Tous ces frais constituent le taux immobilier global (TAEG), à savoir le coût total de l’emprunt immobilier.
      </HomeSectionDescription>
      <HomeSectionDescription>
        <strong>Les frais susmentionnés peuvent être négociés par les emprunteurs.</strong> C’est même largement recommandé. Pour ce faire, il convient de faire appel à un courtier, un professionnel expérimenté dans ce genre de procédures.
      </HomeSectionDescription>
      </HomeContainer>
  )
}

export default RealEstateBank
