import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoPercentageHouse from '../../../assets/percentage-house.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPercentageHouse,
  title: 'Pour calculer son taux d’endettement, c’est très simple : il suffit de se baser sur ses revenus nets imposables, ainsi que sur les éventuels crédits en cours.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TitleNumber = styled.span`
  padding-right: 10px;
`

const RealEstateRateRecap3 = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h3'>
        <TitleNumber>→ 3.</TitleNumber>Avoir une bonne tenue de ses comptes personnels
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Afin d’obtenir un meilleur taux, il convient d’avoir une bonne tenue de ses comptes personnels. Cela signifie donc réussir à épargner tous les mois et ne pas se retrouver en négatif.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        De plus, un facteur très important et déterminant du taux immobilier et surtout de la possibilité d’avoir droit à un prêt immobilier, est le taux d’endettement. Aujourd’hui, les banques doivent respecter le seuil des 35% d’endettement. Avant de formuler une demande de crédit immobilier, il est donc important de connaître son taux.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        À noter que certaines personnes peuvent dépasser légèrement le taux d’endettement recommandé, en justifiant d’une situation financière responsable. Pour ce faire, il est nécessaire d’être 100% transparent sur ses relevés de compte. Cela concerne néanmoins une minorité des prêts (environ 20%).
      </HomeSectionDescription>
    </Container>
  )
}

export default RealEstateRateRecap3
