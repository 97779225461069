import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;
  height: 620px;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
    height: 550px;
  `}

  ${media.greaterThan('xl')`
    max-width: 600px;
    margin-right: 55%;
    margin-left: 5%;
    height: 700px;
  `}

  ${media.greaterThan('xxl')`
    margin-left: auto;
  `}
`

const RealEstateRateHeader = () => (
  <>
    <StyledContainer>
      <Header max={100}>
        Taux immobilier : comment obtenir le meilleur taux pour un achat immobilier ?
      </Header>
      <Subtitle>
        Si vous souhaitez effectuer un achat immobilier, la notion de taux immobilier vous évoque sans doute quelque chose. Le taux immobilier est tout simplement le taux du crédit immobilier, fixé librement par l’établissement bancaire.
      </Subtitle>
      <Description>
        Une chose est sûre, le taux immobilier dépend surtout de la situation de l’emprunteur. Folhomee revient sur la composition du taux immobilier et vous donne les clés pour pouvoir obtenir le meilleur taux avant l’achat de votre bien.
      </Description>
    </StyledContainer>
  </>
)

export default RealEstateRateHeader
