import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoExpert from '../../../assets/expert.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoExpert,
  title: 'À vrai dire, faire appel à un courtier immobilier augmente considérablement vos chances de pouvoir obtenir un financement avec un taux immobilier avantageux.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TitleNumber = styled.span`
  padding-right: 10px;
`
const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
`
const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const RealEstateRateRecap4 = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h3'>
        <TitleNumber>→ 4.</TitleNumber>Faire appel à un courtier en prêts immobiliers pour obtenir le meilleur taux
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les courtiers immobiliers sont spécialisés dans la recherche des meilleurs crédits immobiliers, avec les meilleurs taux.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Si la négociation n’est pas forcément votre point fort, l’appel à un professionnel est vivement recommandé. 
      </HomeSectionDescription>
      <br />
      <HomeSectionDescription>
        Le courtier immobilier monte un dossier très complet en fonction de votre profil emprunteur et contacte plusieurs établissements bancaires adaptés à votre profil pour dénicher le meilleur crédit immobilier.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <BottomText>
        <strong>Folhomee vous met en lien avec une liste de partenaires</strong> pouvant vous accompagner dans votre recherche de financement immobilier.
      </BottomText>
      <StyledButtonContainer>
        <LaunchButton
          link='/services/investissement-pinel'
          title='Négociez le meilleur taux immobilier' />
      </StyledButtonContainer>
    </Container>
  )
}

export default RealEstateRateRecap4
