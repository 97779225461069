import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import RealEstateRateImage from '../../molecules/RealEstateRateImage'
import RealEstateRateHeader from '../../molecules/RealEstateRateHeader'

const StyledRealEstateRateImage = styled(RealEstateRateImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 740px; 
    width: 1200px;
    top: 220px;
  `}
`

const RealEstateRateTopSection = () => (
  <TopSection component={StyledRealEstateRateImage}>
    <RealEstateRateHeader />
  </TopSection>
)

export default RealEstateRateTopSection
