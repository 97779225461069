import React from 'react'

import PictoMoney from '../../../assets/money.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'


const DETAILS_COL = [{
  icon: PictoMoney,
  title: 'Pour résumer, le profil emprunteur reste tout de même très subjectif et dépend essentiellement de l’établissement bancaire. Voilà pourquoi il convient d’aller toquer à plusieurs portes avant de valider une demande de prêt.'
}]

const RealEstateLoanOther = () => {
  return (
    <HomeContainer max={1200}>
      <HomeSectionTitle>
        D'autres éléments comptent
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les habitudes financières, les crédits en cours (même les petits crédits conso) sont également des points sur lesquels l’établissement bancaire va se concentrer pour accorder le crédit et déterminer le taux immobilier.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Évidemment, la pertinence de l’achat immobilier est aussi un élément important : il faut que l’investissement soit en adéquation avec le mode de vie de l’emprunteur. Le projet doit faire sens et le bien immobilier convoité doit avoir un potentiel de revente.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
    </HomeContainer>
  )
}

export default RealEstateLoanOther
