import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '418'
  },
  xxl: {
    height: '500'
  }
}

const TAEGExplanation = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "taeg-explanation.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={true}
    imageData={placeholderImage}
    backgroundColor='paleGrey'>
    <HomeSectionTitle tag='h3'>
      Le Taux Annuel Effectif Global (TAEG)
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le Taux Annuel Effectif Global comprend également d’autres frais qu’il convient d’aborder : frais de dossier, commissions, primes d'assurances...
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En somme, la globalité des frais inclus dans les intérêts du crédit. Si l’objectif est d’emprunter pour un achat immobilier, il convient de soigner son profil d’emprunteur pour pouvoir bénéficier du meilleur taux. En effet, le taux immobilier dépend du profil de chaque demandeur.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default TAEGExplanation
