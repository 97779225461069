import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import SiteSection from '../components/atoms/SiteSection'
import TAEGBorrower from '../components/organisms/TAEGBorrower'
import RealEstateBank from '../components/organisms/RealEstateBank'
import RealEstateLoan from '../components/organisms/RealEstateLoan'
import TAEGComposants from '../components/organisms/TAEGComposants'
import TAEGExplanation from '../components/organisms/TAEGExplanation'
import RealEstateLoanOther from '../components/organisms/RealEstateLoanOther'
import RealEstateLoanIncome from '../components/organisms/RealEstateLoanIncome'
import RealEstateRateRecap1 from '../components/organisms/RealEstateRateRecap1'
import RealEstateRateRecap2 from '../components/organisms/RealEstateRateRecap2'
import RealEstateRateRecap3 from '../components/organisms/RealEstateRateRecap3'
import RealEstateRateRecap4 from '../components/organisms/RealEstateRateRecap4'
import RealEstateRateDefinition from '../components/organisms/RealEstateRateDefinition'
import RealEstateRateProfilLoan from '../components/organisms/RealEstateRateProfilLoan'
import RealEstateRateTopSection from '../components/organisms/RealEstateRateTopSection'
import RealEstateRateComposition from '../components/organisms/RealEstateRateComposition'
import RealEstateRateDescription from '../components/organisms/RealEstateRateDescription'

const TITLE = 'Taux immobilier : avoir le meilleur taux pour un achat immobilier'
const DESCRIPTION = 'Les conseils pour obtenir le meilleur taux pour un achat immobilier.'

const TauxImmobilier = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <RealEstateRateTopSection />
    <SiteSection id='taux-immmobilier-definition'>
      <RealEstateRateDefinition />
    </SiteSection>
    <SiteSection id='TAEG-explanation'>
      <TAEGExplanation />
    </SiteSection>
    <SiteSection id='investissement-deduction'>
      <TAEGBorrower />
    </SiteSection>
    <SiteSection id='taux-immobilier-composition'>
      <RealEstateRateComposition />
    </SiteSection>
    <SiteSection id='taux-immobilier-emprunt'>
      <RealEstateLoan />
    </SiteSection>
    <SiteSection id='taux-immobilier-emprunt-profile'>
      <RealEstateRateProfilLoan />
    </SiteSection>
    <SiteSection id='taux-immobilier-emprunt-revenu'>
      <RealEstateLoanIncome />
    </SiteSection>
    <SiteSection id='taux-immobilier-emprunt-autres'>
      <RealEstateLoanOther />
    </SiteSection>
    <SiteSection id='TAEG-composants'>
      <TAEGComposants />
    </SiteSection>
    <SiteSection id='taux-immobilier-emprunt'>
      <RealEstateBank />
    </SiteSection>
    <SiteSection id='meilleur-taux-immobilier-description'>
      <RealEstateRateDescription />
    </SiteSection>
    <SiteSection id='taux-immobilier-recap-1'>
      <RealEstateRateRecap1 />
    </SiteSection>
    <SiteSection id='taux-immobilier-recap-2'>
      <RealEstateRateRecap2 />
    </SiteSection>
    <SiteSection id='taux-immobilier-recap-3'>
      <RealEstateRateRecap3 />
    </SiteSection>
    <SiteSection id='taux-immobilier-recap-4'>
      <RealEstateRateRecap4 />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

TauxImmobilier.propTypes = {
  location: PropTypes.object.isRequired
}

export default TauxImmobilier
