import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import PictoPurse from '../../../assets/purse.inline.svg'
import PictoExpert from '../../../assets/expert.inline.svg'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoPercentageHouse from '../../../assets/percentage-house.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '1011'
  },
  xl: {
    height: '773'
  }
}

const DETAILS_LINE = [{
  icon: PictoPurse,
  title: 'L’apport personnel ',
  description: 'et la liquidation des crédits en cours'
}, {
  icon: PictoPercentageHouse,
  title: 'Le taux d’endettement',
}, {
  icon: PictoExpert,
  title: 'L’appel à un courtier',
  description: 'pour négocier les meilleurs frais.'
}]

const RealEstateRateDescription = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "meilleur-taux-immobilier.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={false}
    imageData={placeholderImage}
    backgroundColor='paleGrey'>
    <HomeSectionTitle tag='h2'>
      Comment faire pour obtenir le meilleur taux pour un achat immobilier&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      À présent que les bases du taux immobilier ont été posées, Folhomee vous propose des conseils pour optimiser votre taux d’emprunt.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Pour ce faire, la première chose à faire est de <strong>soigner son profil emprunteur.</strong> Il convient de poser tous les éléments précédemment évoqués sur la table, et voir ce qui peut être amélioré pour séduire davantage les banques. Pour rappel, les éléments les plus importants pour un crédit immobilier sont&nbsp;:
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
  </ImageSection>
  )
}

export default RealEstateRateDescription
