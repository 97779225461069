import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import StyledList from '../../atoms/StyledList'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '560'
  },
  xl: {
    height: '497'
  }
}

const TAEGComposants = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "taeg-cost.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={true}
    imageData={placeholderImage}
    backgroundColor='paleGrey'>
    <HomeSectionTitle tag='h3'>
      Assurances, frais de garantie, frais de dossier&hellip; les composantes du TAEG
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Comme précédemment évoqué, le taux immobilier est une composante du TAEG, à savoir le taux global du crédit immobilier.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Le Taux Annuel Effectif Global comprend également d’autres frais qu’il convient d’aborder&nbsp;:
    </HomeSectionDescription>
    <StyledList>
        <li>
          Frais de dossier propres à chaque banque
        </li>
        <li>
          Assurances obligatoires
        </li>
        <li>
          Frais de courtage
        </li>
        <li>
          Contreparties contractuelles
        </li>
      </StyledList>
  </ImageSection>
  )
}

export default TAEGComposants
