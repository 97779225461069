import React from 'react'

import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RealEstateRateDefinition = () => (
  <HomeContainer max={1080}>
    <HomeSectionTitle tag='h2'>
      Qu’est-ce que le taux immobilier&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le taux immobilier désigne le pourcentage d’intérêts à payer par les emprunteurs pour un crédit immobilier.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Autrement dit, le taux immobilier est indiqué dans toutes les offres et contrats de crédit. Le taux immobilier entre dans le calcul du TAEG, pour Taux Annuel Effectif Global : c’est le coût total du crédit, tous frais compris. Celui-ci peut se définir librement par l’établissement bancaire, avec un plafond fixé par la Banque de France.
    </HomeSectionDescription>
  </HomeContainer>
)

export default RealEstateRateDefinition
